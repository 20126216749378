import Skeleton from '@mui/material/Skeleton';
import { styled } from '@mui/material/styles';
import { ThemeProvider } from '@mui/material/styles';
import { muiTheme } from 'themes/exprealtyCA_mui';

const ResultCountWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  margin: '1rem auto',
  width: '100%',
});

export default function SkeletonResultCount() {
  return (
    <ThemeProvider theme={muiTheme}>
      <ResultCountWrapper>
        <Skeleton
          variant="text"
          width={400}
          height={65}
          sx={{
            borderRadius: '8px',
          }}
        />
      </ResultCountWrapper>
    </ThemeProvider>
  );
}
