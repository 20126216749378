declare global {
  interface Window {
    grecaptcha: any;
  }
}

import { useState, useEffect, useRef } from 'react';
import type { AgentList } from 'utils/agent-endpoint';
import InputWithCountrySelect from './input-with-country-select/InputWithCountrySelect';
import InputWithSearchIcon from './input-with-search-icon/InputWithSearchIcon';
import PaginationNav from 'components/control/pagination-nav';
// import LoadingSpinner from 'components/loading-spinner';
import * as AgentActions from 'utils/agent-endpoint';
import { CountryCodeList } from 'types/countries';
import { PAGE_SIZE, AgentType } from 'utils/agent-endpoint';
import AgentCard from './agent-card/AgentCard';
import { useRouter } from 'next/router';
import NextImage from 'next/image';
import Script from 'next/script';
import { trackEvent } from 'utils/google-tag-manager';
import { GTM_VISIT_AGENT_SEARCH_PAGE } from 'constants/events';
import { agentSearchIds } from 'constants/test-constants';
import { isClientSide } from 'utils/host-config';
import SkeletonAgentCard from './agent-card/SkeletonAgentCard';
import SkeletonResultCount from './skeleton/SkeletonResultCount';
import { generateAgentStyles } from 'utils/agent-name-obfuscator';

import styles from './style.module.scss';
import { HeadDataType } from 'components/head-data';

/**
 * Agent search page props
 * @param {AgentList[]} featureAgents - List of featured agents initially displayed on the page
 * @param {CountryCodeList} initialCountry - If URL query contained country code or we get it from site location
 * @param {string} sessionId - sessionId used for agent pagination performance
 * @param {HeadDataType} headerData - Header data for the page
 * @param {AgentType} agentType - Type of search page (Agent or Commercial)
 */
export interface AgentSearchProps {
  initialCountry: CountryCodeList;
  sessionId: string;
  headerData: HeadDataType;
  agentType: AgentType;
}

interface AgentQueryParams {
  [key: string]: string | undefined;
  page: string;
  country: string;
  location?: string;
  name?: string;
}

const AgentSearch = ({ initialCountry, sessionId, agentType = AgentType.RESIDENTIAL }: AgentSearchProps) => {
  const router = useRouter();

  const shouldFetchFeatured = useRef(true);
  const shouldFetchAgents = useRef(true);
  const isCommercial = agentType === AgentType.COMMERCIAL;
  const country = router.query.country as CountryCodeList || initialCountry;
  const selectedLocation = router.query.location ? decodeURIComponent(router.query.location as string) : '';

  const [featuredAgents, setFeaturedAgents] = useState<AgentList[]>([]);
  const [scriptLoaded, setScriptLoaded] = useState(isClientSide() && typeof window.grecaptcha !== 'undefined');
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(() => parseInt(router.query.page as string, 10) || 1);
  // Location query for location input
  const [locationQuery, setLocationQuery] = useState(selectedLocation);
  const [agents, setAgents] = useState<AgentList[]>([]);
  const [selectedName, setSelectedName] = useState(() =>
    router.query.name ? decodeURIComponent(router.query.name as string) : '');
  const [nameQuery, setNameQuery] = useState(selectedName);
  const [isLoading, setIsLoading] = useState(false);
  const isFeatured = !selectedLocation && !selectedName && !isCommercial;
  const [agentStyles, setAgentStyles] = useState<string>('');

  const agentTypeName = isCommercial ? 'Commercial Advisor' : 'eXp Agent';

  useEffect(() => {
    const fetchFeaturedAgents = async () => {
      if (isCommercial || isLoading) return;
      setIsLoading(true);
      shouldFetchFeatured.current = false;
      const response = await AgentActions.featuredAgents(country);
      if (!(response instanceof Error)) {
        setFeaturedAgents(response.agents);
      }
      setIsLoading(false);
    };

    if (!selectedLocation && !isCommercial && scriptLoaded && isFeatured && shouldFetchFeatured.current) {
      fetchFeaturedAgents();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [country, scriptLoaded]);

  useEffect(() => {
    const fetchAgents = async () => {
      if (isLoading) return;
      shouldFetchAgents.current = false;
      setIsLoading(true);
      const params ={
        name: selectedName,
        location: selectedLocation,
        country,
        page,
        session_id: sessionId,
        agentType,
      };
      const data = await AgentActions.searchAgents(params);

      if (!(data instanceof Error)) {
        setAgents(data.agents);
        setCount(data.count);
      }
      setIsLoading(false);
    };

    if (scriptLoaded && shouldFetchAgents.current && router.isReady
      && (selectedName.length > 0 || selectedLocation.length > 0 || isCommercial)) {
      updatePathQuery();
      fetchAgents();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedName, selectedLocation, page, scriptLoaded]);

  useEffect(() => {
    if (isFeatured && !isCommercial) trackEvent(GTM_VISIT_AGENT_SEARCH_PAGE);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const combined = [...featuredAgents, ...agents];
    if (combined.length) {
      setAgentStyles(generateAgentStyles(combined));
    }
  }, [featuredAgents, agents]);

  const handleSelectLocation = (location: any) => {
    updatePathQuery({ location, name: '', page: '1' });
  };

  const handleSelectName = (name: string) => {
    updatePathQuery({ name, location: '', page: '1' });
  };

  const updatePathQuery = (state: any = null) => {
    let params: AgentQueryParams = {
      page: page.toString(),
      country,
    };

    if (selectedLocation !== '') params.location = selectedLocation;
    if (selectedName !== '') params.name = selectedName;

    if (state) params = { ...params, ...state };

    const currentQuery = router.query;
    const queryChanged =
      currentQuery.country !== params.country ||
      currentQuery.page !== params.page ||
      params.location && currentQuery.location !== params.location ||
      currentQuery.name !== params.name;

    if (queryChanged) {
      // Prevent empty query params in URL
      if (params.location === '') delete params.location;
      if (params.name === '') delete params.name;

      router.push(
        {
          pathname: router.pathname,
          query: params,
        },
        undefined,
        { shallow: true }
      );
    }
  };

  const updateCountry = (country: CountryCodeList) => {
    setLocationQuery('');
    setSelectedName('');
    setNameQuery('');
    updatePathQuery({ country, location: '', name: '', page: '1' });
  };

  const handleUpdatePageNumber = (pageNumber: number) => {
    shouldFetchAgents.current = true;
    setPage(pageNumber);
  };

  const renderCount = (): string => {
    if (isFeatured) return `16 Featured ${country} eXp Agents`;
    if (isCommercial) return `${agentTypeName}s`;
    if (selectedLocation.length > 0 ) return `${count} ${selectedLocation.split(',')[0]} ${agentTypeName}${count !== 1 ? 's' : ''}`;
    return `${count} ${country} ${agentTypeName}${count === 1 ? '' : 's'}`;
  };

  const countryName = country === CountryCodeList.CANADA ? 'Canada' : 'US';

  return (
    <div className={styles.component}>
      {agentStyles && <style dangerouslySetInnerHTML={{ __html: agentStyles }} />}
      <div className={`${styles['agent-header']}`}>
        <div className={`${styles['exp-header-banner']}`}>
          <NextImage
            key="exp-banner-white-no-text-1"
            fill
            src="/next/assets/images/agent-search/exp-banner-white-no-text.png"
            alt="eXp Realty Logo"
            priority={true}
          />
        </div>
        <h1 className={styles['agent-header-title']} data-testid={agentSearchIds.agentSearchHeader}>
          Search All {!isCommercial && countryName} {agentTypeName}s
        </h1>

        <div className={styles.inputs}>
          <InputWithCountrySelect
            onCountryChange={updateCountry}
            country={country}
            onLocationSelect={handleSelectLocation}
            locationQuery={locationQuery}
            updateLocationQuery={(query: string) => setLocationQuery(query)}
            allowCountryChange={!isCommercial}
          />

          <InputWithSearchIcon
            onSelectName={handleSelectName}
            country={country}
            nameQuery={nameQuery}
            isCommercial={isCommercial}
          />
        </div>
      </div>

      {(!scriptLoaded || isLoading) && (
        // Leave here temporarily until Skeleton is approved
        // <div style={{ height: '700px' }}>
        //   <LoadingSpinner />
        // </div>
        <>
          <SkeletonResultCount />
          <div className={styles['search-results-container']}>
            {[...Array(4)].map((_, index) => (
              <SkeletonAgentCard key={index} />
            ))}
          </div>
        </>
      )}

      {(scriptLoaded && !isLoading) && (
        <>
          <p className={styles['result-count']} data-testid={agentSearchIds.agentsResultCount}>{renderCount()}</p>
          <div className={styles['search-results-container']}>
            {featuredAgents && featuredAgents.length > 0 && isFeatured && (
              <>
                {featuredAgents.map((agent, index) => (
                  <AgentCard key={index} agent={agent} isCommercial={isCommercial} />
                ))}
              </>
            )}

            {(agents && Array.isArray(agents) && agents.length > 0) &&
              agents.map((agent, index) => (
                <AgentCard key={index} agent={agent} isCommercial={isCommercial} />
              ))}
          </div>
          {(!agents || agents.length === 0) && !isFeatured && (
            <div className={styles['no-results']} data-testid={agentSearchIds.agentNoResultsMessage}>
              <NextImage
                src={`/next/assets/images/agent-search/no-agent-results${isCommercial ? '-commercial' : ''}.png`}
                alt="No results found"
                width={237}
                height={176}
              />
              <h1>We couldn’t find any matches for your search.</h1>
              <p>Check for misspelled words or try a different search term.</p>
            </div>
          )}
        </>
      )}
      <PaginationNav
        pageNumber={page}
        totalPages={Math.ceil(count / PAGE_SIZE) || 1}
        setPageNumber={handleUpdatePageNumber}
      />
      {!scriptLoaded &&
        <Script src={`https://www.google.com/recaptcha/enterprise.js?render=${process.env.NEXT_PUBLIC_RECAPTCHA_KEY}`}
          onLoad={() => setScriptLoaded(true)}
        />
      }
    </div>
  );
};

export default AgentSearch;
