import Skeleton from '@mui/material/Skeleton';
import { styled } from '@mui/material/styles';
import { useIsMobile } from 'hooks/use-size-class';
import { ThemeProvider } from '@mui/material/styles';
import { muiTheme } from 'themes/exprealtyCA_mui';

const CardWrapper = styled('div')(({ theme, isMobile }: { theme: any, isMobile: boolean }) => ({
  display: 'flex',
  flexDirection: isMobile ? 'row' : 'column',
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: theme.shape.borderRadius,
  background: theme.palette.background.paper,
  width: '100%',
  margin: '0.5rem',
  padding: isMobile ? 0 : '1rem',
  minHeight: isMobile ? '150px' : '400px',
  overflow: 'hidden',
}));

const InfoWrapper = styled('div')({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  gap: '0.5rem',
  padding: '1rem',
});

export default function SkeletonAgentCard() {
  const isMobile = useIsMobile();

  return (
    <ThemeProvider theme={muiTheme}>
      <CardWrapper isMobile={isMobile} theme={muiTheme}>
        <Skeleton
          variant="rectangular"
          width={isMobile ? 150 : '100%'}
          height={isMobile ? 150 : 'auto'}
          sx={{
            aspectRatio: isMobile ? 'auto' : '1',
            flexShrink: 0,
          }}
        />
        <InfoWrapper>
          <Skeleton variant="text" width={180} height={24} />
          <Skeleton variant="text" width={120} height={16} />
          <div style={{ marginTop: '0.5rem' }}>
            <Skeleton variant="text" width="100%" height={12} />
            <Skeleton variant="text" width="100%" height={12} />
            <Skeleton variant="text" width="60%" height={12} />
          </div>
        </InfoWrapper>
      </CardWrapper>
    </ThemeProvider>
  );
}
